<template>
  <section>
    <b-card-actions
        ref="first-card"
        class="rezervasyon-card"
        @refresh="refreshStop('second-card')"
    >
      <b-row class="mt-50">
        <div
            class="card-header-row"
            :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}"
        >
          <b-col cols="12">
            <h4 class="text-white d-inline-block">
              Salon Rezervasyon Kaydı
            </h4>
          </b-col>
        </div>
      </b-row>
      <b-row class="table-top">
        <b-col lg="6" md="12" sm="12" cols="12">
          <b-row class="mt-md-2 mt-sm-4 mt-5">
            <b-col lg="6" md="6" sm="12" cols="12">
              <b-form-group
                  label="Cari Kart"
                  label-for="basicInput"
              >
                <v-select
                    v-model="cariKart"
                    :options="cariKartlar"
                    :getOptionLabel="option => option.ad + ' ' + option.soyad + ' - ' + option.firmaUnvan"
                    input-id="invoice-data-client"
                    :clearable="false"
                    @search="(search, loading) => { loading(true)
                                                  $store.dispatch('cariCardlar', {
                                                    uzunluk: 20,
                                                    baslangic: 0,
                                                    cariGrupID: 0,
                                                    searchKey: search,
                                                  }).then(() => loading(false))
                  }"
                >
                  <template #option="{firmaUnvan,adi, soyAdi}">
                    {{ firmaUnvan || `${adi} ${soyAdi}` }}
                  </template>
                  <template #selected-option-container="{ option }">
                    <div class="vs__selected">
                      {{ option.firmaUnvan ? option.firmaUnvan.slice(0, 25) : option.adi + ' ' + option.soyAdi }}
                    </div>
                  </template>
                  <template #list-header>
                    <li
                        v-b-modal.new-cari
                        class="add-new-client-header d-flex align-items-center my-50"
                    >
                      <feather-icon
                          icon="PlusIcon"
                          size="16"
                      />
                      <span class="align-middle ml-25">Yeni Cari Kart</span>
                    </li>
                  </template>
                  <template #no-options>
                    <p>Sonuç Bulunamadı</p>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" cols="12">
              <b-form-group
                  label="Tarife"
                  label-for="basicInput"
              >
                <v-select
                    v-model="tarife"
                    :options="tarifeler"
                    label="text"
                    input-id="invoice-data-client"
                    :clearable="false"
                >
                  <template #no-options>
                    <p>Sonuç Bulunamadı</p>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" cols="12">
              <b-form-group
                  label="Salon"
                  label-for="basicInput"
              >
                <v-select
                    v-model="salon"
                    :options="salonListesi"
                    label="salonAdi"
                    input-id="invoice-data-client"
                    :clearable="false"
                >
                  <template #no-options>
                    <p>Sonuç Bulunamadı</p>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" cols="12">
              <b-form-group
                  label="Kira Tutarı"
                  label-for="basicInput"
              >
                <b-form-input
                    id="basicInput"
                    v-model="kira"
                    autocomplete="off"
                    :step="priceStep"
                />
              </b-form-group>
            </b-col>
            <b-col lg="6" md="6" sm="12" cols="12">
              <b-form-group
                  label="Rezervasyon Notu"
                  label-for="basicInput"
              >
                <b-form-textarea
                    id="textarea-default"
                    v-model="rezNot"
                    rows="2"
                />
              </b-form-group>
            </b-col>
            <b-col lg="3" md="6" sm="12" cols="12">
              <b-form-group
                  label="Başlangıç Tarihi ve Saati"
                  label-for="basicInput"
              >
                <flat-pickr
                    v-model="baslangicTarih"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                />
              </b-form-group>
            </b-col>
            <b-col lg="3" md="6" sm="12" cols="12">
              <b-form-group
                  label="Bitiş Tarihi ve Saati"
                  label-for="basicInput"
              >
                <flat-pickr
                    v-model="bitisTarih"
                    class="form-control"
                    :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
            <b-col cols="12">
              <b-form
                  ref="form"
                  :style="{height: trHeight}"
                  class="repeater-form"
                  @submit.prevent="repeateAgain"
              >
                <b-row
                    v-for="(item, index) in opsions"
                    :id="item.id"
                    :key="item.id"
                    ref="row"
                >
                  <b-col md="5">
                    <b-form-group
                        label="Hizmet adı"
                        label-for="item-name"
                    >
                      <b-form-select v-model="item.name" :options="opsiyonList"
                                     value-field="opsiyonAdi"
                                     text-field="opsiyonAdi"
                      />
                      <!--                      <v-select-->
                      <!--                          v-model="item.name"-->
                      <!--                          :options="opsiyonList"-->
                      <!--                          label="opsiyonAdi"-->
                      <!--                          input-id="opsiyonID"-->
                      <!--                      >-->
                      <!--                        <template #no-options>-->
                      <!--                          <p>Sonuç Bulunamadı</p>-->
                      <!--                        </template>-->
                      <!--                      </v-select>-->
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                        label="Tutarı"
                        label-for="basicInput"
                    >
                      <b-form-input
                          id="cost"
                          v-model="item.price"
                          type="number"
                          :step="priceStep"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                      lg="2"
                      md="3"
                      class="mb-50"
                  >
                    <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-danger"
                        class="mt-0 mt-md-2"
                        @click="removeItem(index)"
                    >
                      <feather-icon
                          icon="XIcon"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="12">
                    <hr>
                  </b-col>
                </b-row>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateAgain"
                >
                  <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                  />
                  <span>Yeni Satır</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    class="text-white float-right bg-primary bg-lighten-2 card-right-button border-0"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none'}"
                    :disabled="cariKart == '' || salon == ''|| tarife == ''|| baslangicTarih ==''|| bitisTarih == ''"
                    @click="rezSave"
                >
                  <font-awesome-icon
                      icon="fa-solid fa-floppy-disk"
                      class="mr-25"
                  />
                  Rezervasyonu Kaydet
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary mr-50"
                    class="text-white float-right bg-primary bg-lighten-2 card-right-button"
                    :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                    @click="newRezCancel"
                >
                  <font-awesome-icon
                      icon="fa-solid fa-arrow-rotate-left"
                      class="mr-25"
                  />
                  Geri Dön
                </b-button>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.opsiyon-manager
                    variant="warning"
                    class="float-right mr-50"
                >
                  <font-awesome-icon
                      icon="fa-solid fa-gear"
                      class="mr-50"
                  />
                  <span>Hizmet Yönetimi</span>
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="6" md="12" sm="12" cols="12">
          <h2 class="text-center mt-md-3 mt-sm-4">
            Rezervasyon Tablosu
          </h2>
          <b-row>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
          <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
              <div class="card-body pb-0 pt-0">
                <full-calendar
                    ref="rezCalendar"
                    :options="calendarOptions"
                    class="full-calendar"
                >
                  <template v-slot:eventContent="val">
                    <b>{{ val.event.extendedProps.cariUnvan }}</b>
                  </template>
                </full-calendar>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-actions>
    <b-modal
        id="new-cari"
        ok-only
        hide-footer
        centered
        size="lg"
        no-close-on-esc
        no-close-on-backdrop
        hide-header
    >
      <form autocomplete="off">
        <b-row>
          <b-col cols="12">
            <h3>
              <b-badge
                  variant="primary"
                  class="modalBadge"
              >
                Yeni Cari Kart
              </b-badge>
            </h3>
          </b-col>
          <b-col
              cols="6"
              class="mt-1"
          >
            <b-form-group
                label="İsim"
                label-for="basicInput"
            >
              <b-form-input
                  id="basicInput"
                  v-model="addCari.adi"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="6"
              class="mt-1"
          >
            <b-form-group
                label="Soyisim"
                label-for="soy-isim"
            >
              <b-form-input
                  id="soy-isim"
                  v-model="addCari.soyAdi"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Cari Grup"
                label-for="basicInput"
            >
              <model-list-select
                  v-model="addCari.cariGrupID"
                  class=""
                  :list="cariGroupGet"
                  option-value="cariGrupID"
                  option-text="grupAdi"

                  placeholder="Cari Grup Seçiniz"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="E-Posta"
                label-for="basicInput"
            >
              <b-form-input
                  id="basicInput"
                  v-model="addCari.ePosta"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Telefon"
                label-for="basicInput"
            >
              <b-form-input
                  id="basicInput"
                  v-model="addCari.telefon"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="İl"
                label-for="basicInput"
            >
              <model-list-select
                  v-model="addCari.il"
                  class=""
                  :list="json"
                  option-value="il_adi"
                  option-text="il_adi"

                  name="unvan"
                  placeholder="İl Seçiniz"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="İlçe"
                label-for="basicInput"
            >
              <model-list-select
                  v-model="addCari.ilce"
                  class=""
                  :list="ilceler"
                  option-value="ilce_adi"
                  option-text="ilce_adi"

                  placeholder="İlçe Seçiniz"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Adres"
                label-for="basicInput"
            >
              <b-form-input
                  id="basicInput"
                  v-model="addCari.adres"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Cari Not"
                label-for="basicInput"
            >
              <b-form-input
                  id="basicInput"
                  v-model="addCari.cariNot"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h3>
              <b-badge
                  variant="success"
                  class="modalBadge"
              >
                Fatura Bilgileri
              </b-badge>
            </h3>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Firma Ünvanı"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="addCari.firmaUnvan"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Vergi No / TC NO"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="addCari.vergiNumarasi"
                  autocomplete="off"
                  max-length="12"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
                label="Banka"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="addCari.banka"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="İBAN"
                label-for="basicInput"
                class="mt-1"
            >
              <b-input-group @paste="ibanPaste">
                <b-input-group-prepend is-text>
                  TR
                </b-input-group-prepend>
                <cleave
                    v-model="addCari.ibann"
                    class="form-control"
                    :options="options.block"
                    placeholder="00 0000 0000 0000 0000 0000 00"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Vergi Dairesi"
                label-for="basicInput"
                class="mt-1"
            >
              <b-form-input
                  id="basicInput"
                  v-model="addCari.vergiDairesi"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              class="d-flex justify-content-end"
          >
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-50"
                @click="addCari.adi && addCari.soyAdi && addCari.cariGrupID && (addCari.vergiNumarasi.length == 10 || addCari.vergiNumarasi.length == 11) ? cariAdd() : false"
                :style="{backgroundColor:$store.getters.COLORS_GET.bgSuccess+'!important',border:'none',cursor: !addCari.adi || !addCari.soyAdi || !addCari.cariGrupID || !(addCari.vergiNumarasi.length == 10 || addCari.vergiNumarasi.length == 11) ? 'default':'pointer',opacity: !addCari.adi || !addCari.soyAdi || !addCari.cariGrupID || !(addCari.vergiNumarasi.length == 10 || addCari.vergiNumarasi.length == 11) ? 0.5: 1}"
                v-b-tooltip="!addCari.adi ? 'Cari Adı Zorunludur.' :  !addCari.soyAdi  ?'Cari Soyismi Zorunludur.' : !addCari.cariGrupID ? 'Cari Grubu Zorunludur.': !(addCari.vergiNumarasi.length == 10 || addCari.vergiNumarasi.length == 11) ? 'Vergi numarası 10 haneli veya TC Kimlik numarası 11 haneli olmalıdır.':'' "
            >
              Kaydet
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="mr-50"
                variant="outline-warning"
                @click="newCariReset"
            >
              Sıfırla
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                @click="newCariKartHide"
            >
              Kapat
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <b-sidebar
        id="add-cari-grup"
        ref="newCariGrup"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
        right
        @change="(val) => $emit('update:is-add-cari-grup-active', val)"
    >
      <template>
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Cari Grup Ekle
          </h5>
          <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="newCariGrupHidden"
          />
        </div>
        <b-form
            id="new-cari-form"
            @submit.prevent
        >
          <b-row>
            <b-col cols="12">
              <b-form-group
                  label="Grup Adı"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="vi-first-name"
                      v-model="grupName"
                      autocomplete="off"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Açıklama"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="AlignCenterIcon"/>
                  </b-input-group-prepend>
                  <b-form-input
                      id="vi-first-name"
                      v-model="grupContent"
                      autocomplete="off"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Grup Türü"
                  label-for="vi-first-name"
              >
                <b-input-group class="input-group-merge">
                  <model-list-select
                      v-model="grupTuru"
                      class=""
                      :list="grupTuruOptions"
                      option-value="value"
                      option-text="text"

                      name="unvan"
                      placeholder="Hızlı Seçim"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                  :disabled="!grupName || !grupTuru"
                  @click="cariGrupSave('kayit')"
              >
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                Kaydet
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  @click="newCariGrupHidden"
              >
                Vazgeç
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </template>
    </b-sidebar>
    <b-sidebar
        id="rezervasyon-edit"
        bg-variant="white"
        right
        backdrop
        shadow
        no-header
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Düzenleniyor
          </h5>
          <div>
            <font-awesome-icon
                icon="fa-solid fa-trash-can"
                class="cursor-pointer"
                @click="etkinlikDelete"
            />
            <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
            />
          </div>
        </div>
        <b-row class="m-50">
          <b-col cols="12">
            <b-form-group
                label="Cari Kart"
                label-for="basicInput"
            >
              <v-select
                  v-model="sidebar.cari"
                  :options="cariKartlar"
                  :getOptionLabel="option => option.firmaUnvan + ' ' + option.ad + ' - ' + option.soyad"
                  input-id="invoice-data-client"
                  :clearable="false"
                  @search="(search, loading) => { loading(true)
                         $store.dispatch('cariCardlar', {
                            uzunluk: 20,
                            baslangic: 0,
                            cariGrupID: 0,
                            searchKey: search,
                          }).then(() => loading(false))
                        }"
              >
                <template #option="{firmaUnvan,adi, soyAdi}">
                  {{ firmaUnvan || `${adi} ${soyAdi}` }}
                </template>
                <template #selected-option-container="{ option }">
                  <div class="vs__selected">
                    {{ option.firmaUnvan ? option.firmaUnvan.slice(0, 25) : option.adi + ' ' + option.soyAdi }}
                  </div>
                  <!--                        <div class="vs__selected">-->
                  <!--                          {{-->
                  <!--                            option.firmaUnvan.length > 30 ? option.firmaUnvan.slice(0, 30) + '...' : option.firmaUnvan-->
                  <!--                          }}  -&#45;&#45;  {{ option.adi }} {{ option.soyAdi }}-->
                  <!--                        </div>-->
                </template>
                <template #list-header>
                  <li
                      v-b-modal.new-cari
                      class="add-new-client-header d-flex align-items-center my-50"
                  >
                    <feather-icon
                        icon="PlusIcon"
                        size="16"
                    />
                    <span class="align-middle ml-25">Yeni Cari Kart</span>
                  </li>
                </template>
                <template #no-options>
                  <p>Sonuç Bulunamadı</p>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Tarife"
                label-for="tarife"
            >
              <v-select
                  v-model="sidebar.tarife"
                  :options="tarifeler"
                  label="text"
                  input-id="value"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Kira Tutarı"
                label-for="basicInput"
            >
              <b-form-input
                  id="basicInput"
                  v-model="sidebar.kiraTutari"
                  placeholder="Tutar"
                  :step="priceStep"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Salon"
                label-for="basicInput"
            >
              <v-select
                  v-model="sidebar.salon"
                  :options="salonListesi"
                  label="salonAdi"
                  input-id="salonID"
                  :clearable="false"
              >
                <template #no-options>
                  <p>Sonuç Bulunamadı</p>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Başlangıç Tarihi ve Saati"
                label-for="basicInput"
            >
              <flat-pickr
                  v-model="sidebar.baslangic"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Bitiş Tarihi ve Saati"
                label-for="basicInput"
            >
              <flat-pickr
                  v-model="sidebar.bitis"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
                label="Rezervasyon Notu"
                label-for="event-description"
            >
              <b-form-textarea
                  id="event-description"
                  v-model="sidebar.not"
                  autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
            <div class="d-flex mt-2">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1 w-100"
                  @click="sideBarUpdate()"
              >
                Güncelle
              </b-button>
              <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-danger"
                  class="w-100"
                  @click="etkinlikDelete()"
              >
                Sil
              </b-button>
            </div>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mt-1 w-100"
                @click="goRez()"
            >
              Rezervasyona Git
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-sidebar>
    <b-sidebar
        id="rez-date"
        bg-variant="white"
        right
        backdrop
        shadow
        no-header
    >
      <template #default="{ hide }">
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            Tarihi ve Saati Ayarlayınız
          </h5>
          <div>
            <font-awesome-icon
                v-if="isEdit"
                icon="fa-solid fa-trash-can"
                class="cursor-pointer"
                @click="etkinlikDelete"
            />
            <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
            />
          </div>
        </div>
        <b-row class="m-50">
          <b-col cols="12">
            <b-form-group>
              <h5 class="font-weight-bold">
                Başlangıç tarihi ve saati
              </h5>
              <flat-pickr
                  v-model="temporaryBaslangicTarih"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group>
              <h5 class="font-weight-bold">
                Bitiş tarihi ve saati
              </h5>
              <flat-pickr
                  v-model="temporaryBitisTarih"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr>
            <div class="d-flex mt-2">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  @click="temporaryDate"
              >
                Kaydet
              </b-button>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-sidebar>
    <b-modal
        id="opsiyon-manager"
        ok-only
        ok-title="Kaydet"
        cancel-title="Kapat"
        modal-class="modal-primary"
        centered
        title="Ek Hizmet Yönetimi"
        size="lg"
        hide-footer
    >
      <b-row>
        <b-col cols="6">
          <table class="table table-hover table-sm">
            <thead>
            <tr>
              <th
                  :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                  scope="col"
              >Hizmet Adı
              </th>
              <th
                  :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                  scope="col"
                  class="text-right"
              >İşlemler
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item ,i) in opsiyonList"
                :key="i"
            >
              <td style="width: 80%">
                {{ item.opsiyonAdi }}
              </td>
              <td style="width: 20%">
                <b-row class="d-flex justify-content-end">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger"
                      class="btn-icon rounded-circle mr-50"
                      size="sm"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                      @click="opsiyonRemove(item.opsiyonID)"
                  >
                    <font-awesome-icon icon="fa-solid fa-trash-can"/>
                  </b-button>
                </b-row>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Ek Hizmet Adı"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="opsiyonName"
                placeholder="Ek hizmet adı"
                autocomplete="off"
                @keyup.enter="opsiyonSave()"
            />
          </b-form-group>
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="float-right border-0"
              :disabled="!opsiyonName"
              @click="opsiyonSave()"
          >
            <font-awesome-icon
                icon="fa-solid fa-plus"
                class="mr-50"
            />
            Ekle
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BSidebar,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem, VBToggle, BFormTextarea,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import _ from 'lodash'
import axios from 'axios'
import router from '@/router'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'

const Turkish = require('flatpickr/dist/l10n/tr.js').default.tr

flatpickr.localize(Turkish)
flatpickr(flatPickr)

export default {
  setup() {
    const json = require('@/assets/turkey-cities.json').data
    const ilceler = []
    return {
      json,
      ilceler,
    }
  },
  components: {
    Cleave,
    flatPickr,
    BFormTextarea,
    FullCalendar,
    dayGridPlugin,
    interactionPlugin,
    timeGridPlugin,
    listPlugin,
    vSelect,
    BSidebar,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  mixins: [heightTransition],
  props: ['show', 'selectDate'],
  data() {
    return {
      options: {
        block: {
          blocks: [2, 4, 4, 4, 4, 4, 2],
          uppercase: true,
        },
      },
      secondSelectedDate: '',
      opsiyonName: '',
      opsions: [{
        id: 1,
        name: '',
        price: '',
        prevHeight: 0,
      }],
      nextTodoId: 2,
      sidebar: {
        rezervasyonID: null,
        not: null,
        baslangic: null,
        bitis: null,
        tarife: null,
        cari: null,
        kiraTutari: null,
      },
      tarifeler: [
        {
          value: '1',
          text: 'Öğretmen',
        }, {
          value: '2',
          text: 'Kamu',
        }, {
          value: '3',
          text: 'Sivil',
        },
      ],
      isEdit: false,
      editID: '',
      grupName: '',
      grupContent: '',
      grupTuruOptions: [
        {
          value: '1',
          text: 'Müşteriler',
        }, {
          value: '2',
          text: 'Tedarikçiler',
        }, {
          value: '3',
          text: 'Personeller',
        }, {
          value: '4',
          text: 'Diğer',
        },
      ],
      grupTuru: {
        value: '1',
        text: 'Müşteriler',
      },
      addCari: {
        cariGrupID: '',
        cariCardID: '',
        adi: '',
        soyAdi: '',
        adres: '',
        ePosta: '',
        telefon: '',
        firmaUnvan: '',
        vergiNumarasi: '',
        vergiDairesi: '',
        il: '',
        ilce: '',
        cariNot: '',
        banka: '',
        ibann: '',
      },
      cariKart: '',
      baslangicTarih: '',
      bitisTarih: '',
      temporaryBaslangicTarih: '',
      temporaryBitisTarih: '',
      salon: '',
      kira: '',
      tarife: '',
      rezNot: '',
      baslangic: '',
      bitis: '',
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        locale: 'tr',
        height: 750,
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        initialView: 'dayGridMonth',
        dateClick: this.dateClick,
        eventClick: this.eventClick,
        eventDrop: this.eventDrop,
        eventClassNames: this.eventClassNames,
        datesSet: this.dateSet,
        editable: true,
        eventResizableFromStart: true,
        dragScroll: true,
        fixedWeekCount: false,
        dayMaxEvents: 3,
        moreLinkText: 'Daha fazla etkinlik',
        noEventsContent: 'Etkinlik Bulunamadı',
        buttonText: {
          today: 'Bugün',
          month: 'Aylık',
          week: 'Haftalık',
          day: 'Günlük',
          list: 'Liste',
        },
        allDayText: 'Tüm Gün',
        navLinks: true,
        calendarsColor: {
          1: 'warning',
          2: 'success',
          3: 'danger',
        },
        eventColor: '#378006',
        events: this.getEvent(),
      },
    }
  },
  computed: {
    opsiyonList() {
      return Object.values(store.getters.OPSIYON_LIST)
    },
    salonListesi() {
      return Object.values(store.getters.SALON_LISTESI_GET)
    },
    cariGroupGet() {
      const grupList = []
      grupList.push(Object.values(store.getters.CARI_GROUP_GET))
      grupList[0].push({
        grupAdi: 'Yeni Cari Grup Oluştur',
        cariGrupID: '-1',
        yeni: true,
      })
      return _.orderBy(grupList[0], ['yeni', 'toplamCari'], ['asc', 'desc'])
    },
    cariKartlar() {
      return Object.values(store.getters.CARI_CARD_GET)
    },
    newCariKartHide() {
      this.$bvModal.hide('new-cari')
      const t = this
      t.addCari.adi = ''
      t.addCari.soyAdi = ''
      t.addCari.adres = ''
      t.addCari.ePosta = ''
      t.addCari.telefon = ''
      t.addCari.firmaUnvan = ''
      t.addCari.vergiDairesi = ''
      t.addCari.vergiNumarasi = ''
      t.addCari.il = ''
      t.addCari.ilce = ''
      t.addCari.cariNot = ''
      t.addCari.banka = ''
      t.addCari.ibann = ''
    },
  },
  watch: {
    addCari: {
      deep: true,
      handler(newVal) {
        if (newVal.cariGrupID == -1) {
          this.$bvModal.hide('new-cari')
          this.$root.$emit('bv::toggle::collapse', 'add-cari-grup')
        }
        // todo SEHIR_ATAMA
        if (newVal.il) {
          this.ilceler = this.json.find(x => x.il_adi === newVal.il).ilceler
        }
      },
    },
    addItem: {
      deep: true,
      handler(newVal) {
        if (newVal.grupID == -1) {
          this.$root.$emit('bv::toggle::collapse', 'add-stok-grup')
          this.$bvModal.hide('new')
          this.isNewGroup = true
        }
      },
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
    store.dispatch('cariGrup')
    store.dispatch('cariCardlar', {
      uzunluk: 20,
      baslangic: 0,
      cariGrupID: 0,
    })
    store.dispatch('salonListesi')
    store.dispatch('opsiyonListesi')
  },
  beforeDestroy() {
    console.clear()
  },
  methods: {
    ibanPaste(event) {
      const veri = event.clipboardData.getData('text')
      if (veri.includes('TR')) {
        this.addCari.ibann = veri.slice(2, 32)
      } else {
        this.addCari.ibann = veri.slice(0, 40)
      }
    },
    opsiyonRemove(id) {
      store.dispatch('salonOpsiyonRemove', id)
          .then((res, position = 'bottom-right') => {
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    opsiyonSave() {
      store.dispatch('salonOpsiyonSave', this.opsiyonName)
          .then((res, position = 'bottom-right') => {
            res.result.status == 200 ? this.opsiyonName = '' : ''
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    newRezCancel() {
      this.$emit('dataReturned', false)
    },
    temporaryDate() {
      const self = this
      this.secondSelectedDate = this.temporaryBaslangicTarih
      this.baslangicTarih = this.temporaryBaslangicTarih
      this.bitisTarih = this.temporaryBitisTarih
      this.$root.$emit('bv::toggle::collapse', 'rez-date')
      const data = {
        crm_token: localStorage.getItem('buroUserToken'),
        serviceName: 'salon',
        methodName: 'rezervasyonListesiSearch',
        baslangic: 0,
        uzunluk: 100,
        searchKey: '',
      }
      $.ajax({
        type: 'POST',
        url: store.state.POST_URL,
        data,
        success(res) {
          store.commit('setToken', res.userToken)
          const calendarApi = self.$refs.rezCalendar.getApi()
          calendarApi.getEventSources()
              .forEach(source => {
                source.remove()
              })
          const yeniVeri = []
          Object.values(res.liste)
              .forEach(item => {
                const event = {
                  cariUnvan: item.cariUnvan,
                  baslangic: item.girisSaat,
                  bitis: item.cikisSaat,
                  start: item.girisSaat,
                  end: item.cikisSaat,
                  salonAdi: item.salonAdi,
                  salonID: item.salonID,
                  rezervasyonID: item.rezervasyonID,
                  tarifeID: item.tarifeID,
                  toplamTutar: item.toplamTutar,
                  rezervasyonNotu: item.rezervasyonNotu,
                  kiraTutari: item.kiraTutari,
                  opsiyonTutari: item.opsiyonTutari,
                  cariKartID: item.cariKartID,
                }
                yeniVeri.push(event)
              })
          yeniVeri.push({
            baslangic: self.baslangicTarih,
            bitis: self.bitisTarih,
            cariKartID: '',
            cariUnvan: 'Seçildi',
            end: self.bitisTarih,
            kiraTutari: '',
            opsiyonTutari: '',
            rezervasyonID: '',
            rezervasyonNotu: '',
            salonAdi: '',
            salonID: '',
            start: self.baslangicTarih,
            tarifeID: '',
            toplamTutar: '',
          })
          calendarApi.addEventSource(yeniVeri)
        },
        dataType: 'json',
      })
    },
    rezSave() {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'salon')
      fd.append('methodName', 'salonRezervasyonKaydet')
      fd.append('saveData[rezervasyonBilgileri][cariKartID]', this.cariKart.cariKartID)
      fd.append('saveData[rezervasyonBilgileri][rezervasyonNotu]', this.rezNot)
      fd.append('saveData[rezervasyonBilgileri][girisSaat]', this.baslangicTarih)
      fd.append('saveData[rezervasyonBilgileri][cikisSaat]', this.bitisTarih)
      fd.append('saveData[rezervasyonBilgileri][kiraTutari]', this.kira || 0)
      fd.append('saveData[rezervasyonBilgileri][salonID]', this.salon.salonID)
      fd.append('saveData[rezervasyonBilgileri][tarifeID]', this.tarife.value)
      let index = 0
      this.opsions.forEach(item => {
        if (item.name != '') {
          fd.append(`saveData[opsiyonList][${index}][opsiyonAdi]`, item.name)
          fd.append(`saveData[opsiyonList][${index}][tutar]`, item.price.replace(/,/g, '.') || 0)
          index++
        }
      })
      axios.post(store.state.POST_URL, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              router.push({
                name: 'rezervasyonDetay',
                params: { id: res.data.data.rezervasyonBilgileri.rezervasyonID },
              })
              this.reset()
              this.dateSet()
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
                store.commit('setToken', res.data.userToken)
              } else {
                const mesaj = this.$store.getters.notificationSettings(res.data)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'warning',
                    },
                  })
                }
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    repeateAgain() {
      this.opsions.push({
        id: this.nextTodoId += this.nextTodoId,
        name: '',
        price: '',
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.opsions.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    dateClick(val) {
      const t = this
      this.$root.$emit('bv::toggle::collapse', 'rez-date')
      t.temporaryBaslangicTarih = val.dateStr
      t.temporaryBitisTarih = val.dateStr
    },
    eventDrop(val) {
      if (val.event._def.extendedProps.rezervasyonID) {
        this.$swal({
          title: 'Tarihi değiştirmek istediğinize eminmisiniz?',
          text: 'Saat aynı kalır. Sadece Tarih değişir!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Onayla',
          cancelButtonText: 'Vazgeç',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const startHour = val.event._def.extendedProps.baslangic.slice(11, 19)
                const endHour = val.event._def.extendedProps.bitis.slice(11, 19)
                const veri = val.event._instance.range.start.toLocaleString()
                    .slice(0, 10)
                const day = veri.slice(0, 2)
                const mounth = veri.slice(3, 5)
                const year = veri.slice(6, 10)
                const fullTimeStart = `${year}-${mounth}-${day} ${startHour}`
                const fullTimeEnd = `${year}-${mounth}-${day} ${endHour}`
                const fd = new FormData()
                fd.append('crm_token', store.getters.TOKEN_GET)
                fd.append('serviceName', 'salon')
                fd.append('methodName', 'salonRezervasyonDuzenle')
                fd.append('rezervasyonID', val.event._def.extendedProps.rezervasyonID)
                fd.append('updateList[girisSaat]', fullTimeStart)
                fd.append('updateList[cikisSaat]', fullTimeEnd)
                axios.post(store.state.POST_URL, fd, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                })
                    .then((res, position = 'bottom-right') => {
                      if (res.data.result.status == 200) {
                        this.dateSet()
                        const mesaj = this.$store.getters.notificationSettings(res.data)
                        if (mesaj) {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Başarılı',
                              text: mesaj,
                              icon: 'ThumbsUpIcon',
                              variant: 'success',
                            },
                          }, { position })
                          store.commit('setToken', res.data.userToken)
                        } else {
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'Hata',
                              text: mesaj,
                              icon: 'ThumbsDownIcon',
                              variant: 'warning',
                            },
                          })
                        }
                      } else {
                        this.dateSet()
                      }
                    })
                    .catch(err => {
                      this.dateSet()
                      const mesaj = this.$store.getters.notificationSettings(err.data)
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    })
              } else {
                this.dateSet()
              }
            })
      } else {
        this.dateSet()
      }
    },
    sideBarUpdate() {
      axios.post(store.state.POST_URL, this.updateSchema(), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
          .then((res, position = 'bottom-right') => {
            if (res.data.result.status == 200) {
              this.dateSet()
              this.$root.$emit('bv::toggle::collapse', 'rezervasyon-edit')
              const mesaj = this.$store.getters.notificationSettings(res.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
                store.commit('setToken', res.data.userToken)
              } else {
                const mesaj = this.$store.getters.notificationSettings(res.data)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'warning',
                    },
                  })
                }
              }
            } else {
              const mesaj = this.$store.getters.notificationSettings(err.data)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = this.$store.getters.notificationSettings(err.data)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    goRez() {
      router.push({
        name: 'rezervasyonDetay',
        params: { id: this.sidebar.rezervasyonID },
      })
    },
    updateSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.getters.TOKEN_GET)
      fd.append('serviceName', 'salon')
      fd.append('methodName', 'salonRezervasyonDuzenle')
      fd.append('rezervasyonID', this.sidebar.rezervasyonID)
      fd.append('updateList[girisSaat]', this.sidebar.baslangic)
      fd.append('updateList[cikisSaat]', this.sidebar.bitis)
      fd.append('updateList[kiraTutari]', this.sidebar.kiraTutari)
      fd.append('updateList[salonID]', this.sidebar.salon.salonID)
      fd.append('updateList[tarifeID]', this.sidebar.tarife.value)
      fd.append('updateList[cariKartID]', this.sidebar.cari.cariKartID)
      fd.append('updateList[rezervasyonNotu]', this.sidebar.not)
      return fd
    },
    etkinlikDelete() {
      this.$swal({
        title: 'Rezervasyonu Sil',
        text: 'Bu işlem geri alınamaz!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet Sil',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
          .then(result => {
            if (result.value) {
              store.dispatch('rezervasyonRemove', this.editID)
                  .then((res, position = 'bottom-right') => {
                    if (res.result.status == 200) {
                      this.$root.$emit('bv::toggle::collapse', 'rezervasyon-edit')
                      store.dispatch('rezervasyonListesi', {
                        uzunluk: this.perPage,
                        baslangic: 0,
                      })
                          .then(res => this.totalRows = res.toplamKayit, this.dateSet())
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Başarılı',
                          text: res.result.errMsg,
                          icon: 'ThumbsUpIcon',
                          variant: 'success',
                        },
                      }, { position })
                    } else {
                      const mesaj = store.getters.notificationSettings(res)
                      if (mesaj) {
                        this.$toast({
                          component: ToastificationContent,
                          props: {
                            title: 'Hata!',
                            text: mesaj,
                            icon: 'ThumbsDownIcon',
                            variant: 'danger',
                          },
                        })
                      }
                    }
                  })
                  .catch(err => {
                    const mesaj = store.getters.notificationSettings(err)
                    if (mesaj) {
                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: 'Hata!',
                          text: mesaj,
                          icon: 'ThumbsDownIcon',
                          variant: 'danger',
                        },
                      })
                    }
                  })
            }
          })
    },
    eventClick(val) {
      const t = this
      t.editID = val.event.extendedProps.rezervasyonID
      if (val.event.extendedProps.rezervasyonID) {
        t.$root.$emit('bv::toggle::collapse', 'rezervasyon-edit')
      } else {
        const startHour = val.event._def.extendedProps.baslangic.slice(11, 19)
        const endHour = val.event._def.extendedProps.bitis.slice(11, 19)
        const veri = val.event._instance.range.start.toLocaleString()
            .slice(0, 10)
        const day = veri.slice(0, 2)
        const mounth = veri.slice(3, 5)
        const year = veri.slice(6, 10)
        const fullTimeStart = `${year}-${mounth}-${day} ${startHour}`
        const fullTimeEnd = `${year}-${mounth}-${day} ${endHour}`
        t.temporaryBaslangicTarih = fullTimeStart
        t.temporaryBitisTarih = fullTimeEnd
        t.$root.$emit('bv::toggle::collapse', 'rez-date')
      }
      if (val.event.extendedProps.cariKartID) {
        store.dispatch('cariDetay', {
          cariKartID: val.event.extendedProps.cariKartID,
        })
            .then(res => t.sidebar.cari = res.data.cariData)
      }
      t.sidebar.baslangic = val.event.start
      t.sidebar.bitis = val.event.end
      t.sidebar.salon = this.salonListesi.find(x => x.salonID == val.event.extendedProps.salonID)
      t.sidebar.not = val.event.extendedProps.rezervasyonNotu
      t.sidebar.rezervasyonID = val.event.extendedProps.rezervasyonID
      t.sidebar.kiraTutari = val.event.extendedProps.kiraTutari
      t.sidebar.tarife = val.event.extendedProps.tarifeID == 1 ? {
        value: '1',
        text: 'Öğretmen',
      } : val.event.extendedProps.tarifeID == 2 ? {
        value: '2',
        text: 'Kamu',
      } : {
        value: '3',
        text: 'Sivil',
      }
    },
    eventClassNames(val) {
      const color = this.calendarOptions.calendarsColor[val.event._def.extendedProps.tarifeID]
      return `bg-light-${color} border border-${color}`
    },
    dateSet() {
      const self = this
      const data = {
        crm_token: localStorage.getItem('buroUserToken'),
        serviceName: 'salon',
        methodName: 'rezervasyonListesiSearch',
        baslangic: 0,
        uzunluk: 100,
        searchKey: '',
      }
      $.ajax({
        type: 'POST',
        url: store.state.POST_URL,
        data,
        success(res) {
          store.commit('setToken', res.userToken)
          const calendarApi = self.$refs.rezCalendar.getApi()
          calendarApi.getEventSources()
              .forEach(source => {
                source.remove()
              })
          const yeniVeri = []
          if (self.secondSelectedDate) {
            yeniVeri.push({
              baslangic: `${self.secondSelectedDate}`,
              bitis: `${self.secondSelectedDate}`,
              cariKartID: '',
              cariUnvan: 'Seçildi',
              end: `${self.secondSelectedDate}`,
              start: `${self.secondSelectedDate}`,
            })
            self.baslangicTarih = `${self.secondSelectedDate}`
            self.bitisTarih = `${self.secondSelectedDate}`
          } else {
            if (self.selectDate) {
              yeniVeri.push({
                baslangic: `${self.selectDate} 12:00:00`,
                bitis: `${self.selectDate} 12:00:00`,
                cariKartID: '',
                cariUnvan: 'Seçildi',
                end: `${self.selectDate} 12:00:00`,
                start: `${self.selectDate} 12:00:00`,
              })
              self.baslangicTarih = `${self.selectDate} 12:00:00`
              self.bitisTarih = `${self.selectDate} 12:00:00`
            }
          }
          Object.values(res.liste)
              .forEach(item => {
                const event = {
                  cariUnvan: item.cariUnvan,
                  baslangic: item.girisSaat,
                  bitis: item.cikisSaat,
                  start: item.girisSaat,
                  end: item.cikisSaat,
                  salonAdi: item.salonAdi,
                  salonID: item.salonID,
                  rezervasyonID: item.rezervasyonID,
                  tarifeID: item.tarifeID,
                  toplamTutar: item.toplamTutar,
                  rezervasyonNotu: item.rezervasyonNotu,
                  kiraTutari: item.kiraTutari,
                  opsiyonTutari: item.opsiyonTutari,
                  cariKartID: item.cariKartID,
                }
                yeniVeri.push(event)
              })
          calendarApi.addEventSource(yeniVeri)
        },
        dataType: 'json',
      })
    },
    getEvent() {
    },
    cariAdd() {
      store.dispatch('cariCardSave', {
        adi: this.addCari.adi,
        soyAdi: this.addCari.soyAdi,
        adres: this.addCari.adres,
        ePosta: this.addCari.ePosta,
        telefon: this.addCari.telefon,
        faturaUnvan: this.addCari.firmaUnvan,
        vergiDairesi: this.addCari.vergiDairesi,
        vergiNumarasi: this.addCari.vergiNumarasi,
        bakiye: this.addCari.bakiye,
        cariNot: this.addCari.cariNot,
        il: this.addCari.il,
        ilce: this.addCari.ilce,
        banka: this.addCari.banka,
        ibann: this.addCari.ibann,
        cariGrupID: this.addCari.cariGrupID,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.cariKart = { ...res.data }
              this.newCariReset()
              this.$bvModal.hide('new-cari')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: res.result.errMsg,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
    newCariGrupHidden() {
      this.addCari.cariGrupID = ''
      this.$refs.newCariGrup.hide()
      this.$bvModal.show('new-cari')
    },
    cariGrupSave(islem) {
      store.dispatch('cariGroupSave', {
        tur: islem === 'kayit' ? this.grupTuru.value : '',
        adi: islem === 'kayit' ? this.grupName : '',
        aciklama: islem === 'kayit' ? this.grupContent : '',
        cariGrupID: islem === 'edit' ? this.editData.cariGrupID : '',
        islem,
      })
          .then((res, position = 'bottom-right') => {
            setTimeout(() => {
              this.addCari.cariGrupID = res.result.data.cariGrupID
            }, 400)
            this.$refs.newCariGrup.hide()
            this.$bvModal.show('new-cari')
            this.grupName = ''
            this.grupContent = ''
            this.grupTuruDefault()
            const mesaj = store.getters.notificationSettings(res)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Başarılı',
                  text: mesaj,
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              }, { position })
            }
          })
          .catch(err => {
            this.addCari.cariGrupID = ''
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    newCariReset() {
      this.addCari.adi = ''
      this.addCari.soyAdi = ''
      this.addCari.adres = ''
      this.addCari.ePosta = ''
      this.addCari.telefon = ''
      this.addCari.firmaUnvan = ''
      this.addCari.vergiDairesi = ''
      this.addCari.vergiNumarasi = ''
      this.addCari.il = ''
      this.addCari.ilce = ''
      this.addCari.cariNot = ''
      this.addCari.banka = ''
      this.addCari.ibann = ''
    },
    reset() {
      this.baslangicTarih = ''
      this.bitisTarih = ''
      this.temporaryBaslangicTarih = ''
      this.temporaryBitisTarih = ''
      this.cariKart = ''
      this.tarife = ''
      this.kira = ''
      this.salon = ''
      this.rezNot = ''
      this.opsions = [{
        id: 1,
        name: '',
        price: '',
        prevHeight: 0,
      }]
      this.nextTodoId = 2
      this.editID = ''
    },
  },
}
</script>
<style>
.rezervasyon-card .card-header {
  display: none !important;
}

@media (max-width: 470px) {
  .fc-button-group {
    display: none !important;
  }
}

</style>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
<style lang="scss">
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1rem !important;
}

@import "@core/scss/vue/apps/calendar.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
}

.table-top {
  margin-top: 3.5% !important;
}

.card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
}

.cari-group-edit .card-header-row {
  width: 102% !important;
  position: absolute;
  background-color: lightslategrey;
  margin: 0 -1.5% !important;
  border-radius: 6px;
  background-color: #F48484 !important;
}

h4 {
  margin-bottom: 0 !important;
  padding: 6px;
}

#new-cari-form {
  padding: 30px !important;
}

.fc-daygrid-event b {
  white-space: normal !important;
}

.liste-alani .menu {
  height: 120px !important;
}

.liste-alani .menu > .item {
  padding: 0.48571429rem 0.54285714rem !important;
}

</style>
